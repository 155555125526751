// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agronomia-forragens-js": () => import("./../../../src/pages/agronomia-forragens.js" /* webpackChunkName: "component---src-pages-agronomia-forragens-js" */),
  "component---src-pages-antibioterapia-bovinos-js": () => import("./../../../src/pages/antibioterapia-bovinos.js" /* webpackChunkName: "component---src-pages-antibioterapia-bovinos-js" */),
  "component---src-pages-aspiracao-folicular-js": () => import("./../../../src/pages/aspiracao-folicular.js" /* webpackChunkName: "component---src-pages-aspiracao-folicular-js" */),
  "component---src-pages-auditor-interno-wfq-galinhas-poedeiras-js": () => import("./../../../src/pages/auditor-interno-wfq-galinhas-poedeiras.js" /* webpackChunkName: "component---src-pages-auditor-interno-wfq-galinhas-poedeiras-js" */),
  "component---src-pages-best-practice-hens-js": () => import("./../../../src/pages/best-practice-hens.js" /* webpackChunkName: "component---src-pages-best-practice-hens-js" */),
  "component---src-pages-bioseguranca-avicultura-js": () => import("./../../../src/pages/bioseguranca-avicultura.js" /* webpackChunkName: "component---src-pages-bioseguranca-avicultura-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diagnostico-clinico-js": () => import("./../../../src/pages/diagnostico-clinico.js" /* webpackChunkName: "component---src-pages-diagnostico-clinico-js" */),
  "component---src-pages-ecografia-palpacao-js": () => import("./../../../src/pages/ecografia-palpacao.js" /* webpackChunkName: "component---src-pages-ecografia-palpacao-js" */),
  "component---src-pages-ecografia-palpacao-turma-2-js": () => import("./../../../src/pages/ecografia-palpacao-turma2.js" /* webpackChunkName: "component---src-pages-ecografia-palpacao-turma-2-js" */),
  "component---src-pages-epa-js": () => import("./../../../src/pages/EPA.js" /* webpackChunkName: "component---src-pages-epa-js" */),
  "component---src-pages-gestao-ambiental-bovinos-js": () => import("./../../../src/pages/gestao-ambiental-bovinos.js" /* webpackChunkName: "component---src-pages-gestao-ambiental-bovinos-js" */),
  "component---src-pages-gestao-engordas-borregos-js": () => import("./../../../src/pages/gestao-engordas-borregos.js" /* webpackChunkName: "component---src-pages-gestao-engordas-borregos-js" */),
  "component---src-pages-gestao-ganadaria-js": () => import("./../../../src/pages/gestao-ganadaria.js" /* webpackChunkName: "component---src-pages-gestao-ganadaria-js" */),
  "component---src-pages-gestao-lideranca-js": () => import("./../../../src/pages/gestao-lideranca.js" /* webpackChunkName: "component---src-pages-gestao-lideranca-js" */),
  "component---src-pages-gestao-saude-nutricao-feedlots-bovinos-js": () => import("./../../../src/pages/gestao-saude-nutricao-feedlots-bovinos.js" /* webpackChunkName: "component---src-pages-gestao-saude-nutricao-feedlots-bovinos-js" */),
  "component---src-pages-ia-js": () => import("./../../../src/pages/IA.js" /* webpackChunkName: "component---src-pages-ia-js" */),
  "component---src-pages-ia-pequenos-ruminantes-js": () => import("./../../../src/pages/IA-pequenos-ruminantes.js" /* webpackChunkName: "component---src-pages-ia-pequenos-ruminantes-js" */),
  "component---src-pages-ia-pequenos-ruminantes-turma-2-js": () => import("./../../../src/pages/IA-pequenos-ruminantes-turma2.js" /* webpackChunkName: "component---src-pages-ia-pequenos-ruminantes-turma-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maneio-comportamento-vacadas-js": () => import("./../../../src/pages/maneio-comportamento-vacadas.js" /* webpackChunkName: "component---src-pages-maneio-comportamento-vacadas-js" */),
  "component---src-pages-maneio-ovino-js": () => import("./../../../src/pages/maneio-ovino.js" /* webpackChunkName: "component---src-pages-maneio-ovino-js" */),
  "component---src-pages-marketing-agronegocio-js": () => import("./../../../src/pages/marketing-agronegocio.js" /* webpackChunkName: "component---src-pages-marketing-agronegocio-js" */),
  "component---src-pages-necropsia-suinos-js": () => import("./../../../src/pages/necropsia-suinos.js" /* webpackChunkName: "component---src-pages-necropsia-suinos-js" */),
  "component---src-pages-necropsias-urgencias-coelhos-js": () => import("./../../../src/pages/necropsias-urgencias-coelhos.js" /* webpackChunkName: "component---src-pages-necropsias-urgencias-coelhos-js" */),
  "component---src-pages-nutricao-bovinos-leiteiros-js": () => import("./../../../src/pages/nutricao-bovinos-leiteiros.js" /* webpackChunkName: "component---src-pages-nutricao-bovinos-leiteiros-js" */),
  "component---src-pages-pastoreio-maneio-extensivo-turma-1-js": () => import("./../../../src/pages/pastoreio-maneio-extensivo-turma1.js" /* webpackChunkName: "component---src-pages-pastoreio-maneio-extensivo-turma-1-js" */),
  "component---src-pages-pastoreio-maneio-extensivo-turma-2-js": () => import("./../../../src/pages/pastoreio-maneio-extensivo-turma2.js" /* webpackChunkName: "component---src-pages-pastoreio-maneio-extensivo-turma-2-js" */),
  "component---src-pages-patologias-cunicultura-js": () => import("./../../../src/pages/patologias-cunicultura.js" /* webpackChunkName: "component---src-pages-patologias-cunicultura-js" */),
  "component---src-pages-pecuaria-nespresso-js": () => import("./../../../src/pages/pecuaria-nespresso.js" /* webpackChunkName: "component---src-pages-pecuaria-nespresso-js" */),
  "component---src-pages-podologia-aparo-cascos-js": () => import("./../../../src/pages/podologia-aparo-cascos.js" /* webpackChunkName: "component---src-pages-podologia-aparo-cascos-js" */),
  "component---src-pages-qualidade-agua-producao-suina-js": () => import("./../../../src/pages/qualidade-agua-producao-suina.js" /* webpackChunkName: "component---src-pages-qualidade-agua-producao-suina-js" */),
  "component---src-pages-qualidade-de-carne-js": () => import("./../../../src/pages/qualidade-de-carne.js" /* webpackChunkName: "component---src-pages-qualidade-de-carne-js" */),
  "component---src-pages-selecao-genetica-js": () => import("./../../../src/pages/selecao-genetica.js" /* webpackChunkName: "component---src-pages-selecao-genetica-js" */),
  "component---src-pages-transferencia-embrioes-js": () => import("./../../../src/pages/transferencia-embrioes.js" /* webpackChunkName: "component---src-pages-transferencia-embrioes-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wq-bovinos-carne-js": () => import("./../../../src/pages/WQ-bovinos-carne.js" /* webpackChunkName: "component---src-pages-wq-bovinos-carne-js" */),
  "component---src-pages-wq-bovinos-leite-js": () => import("./../../../src/pages/WQ-bovinos-leite.js" /* webpackChunkName: "component---src-pages-wq-bovinos-leite-js" */),
  "component---src-pages-wq-engorda-suinos-js": () => import("./../../../src/pages/WQ-engorda-suinos.js" /* webpackChunkName: "component---src-pages-wq-engorda-suinos-js" */),
  "component---src-pages-wq-galinhas-poedeiras-js": () => import("./../../../src/pages/WQ-galinhas-poedeiras.js" /* webpackChunkName: "component---src-pages-wq-galinhas-poedeiras-js" */),
  "component---src-pages-wq-galinhas-poedeiras-turma-2-js": () => import("./../../../src/pages/WQ-galinhas-poedeiras-turma-2.js" /* webpackChunkName: "component---src-pages-wq-galinhas-poedeiras-turma-2-js" */),
  "component---src-pages-wq-js": () => import("./../../../src/pages/WQ.js" /* webpackChunkName: "component---src-pages-wq-js" */),
  "component---src-pages-wq-matadouro-bovinos-js": () => import("./../../../src/pages/WQ-matadouro-bovinos.js" /* webpackChunkName: "component---src-pages-wq-matadouro-bovinos-js" */),
  "component---src-pages-wq-matadouro-pequenos-ruminantes-js": () => import("./../../../src/pages/WQ-matadouro-pequenos-ruminantes.js" /* webpackChunkName: "component---src-pages-wq-matadouro-pequenos-ruminantes-js" */),
  "component---src-pages-wq-matadouro-suinos-js": () => import("./../../../src/pages/WQ-matadouro-suinos.js" /* webpackChunkName: "component---src-pages-wq-matadouro-suinos-js" */),
  "component---src-pages-wq-ovelhas-cabras-leite-js": () => import("./../../../src/pages/WQ-ovelhas-cabras-leite.js" /* webpackChunkName: "component---src-pages-wq-ovelhas-cabras-leite-js" */),
  "component---src-pages-wq-perus-js": () => import("./../../../src/pages/WQ-perus.js" /* webpackChunkName: "component---src-pages-wq-perus-js" */),
  "component---src-pages-wq-porcas-reprodutoras-leitoes-js": () => import("./../../../src/pages/WQ-porcas-reprodutoras-leitoes.js" /* webpackChunkName: "component---src-pages-wq-porcas-reprodutoras-leitoes-js" */)
}

